import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Button } from '@independent-software/typeui/controls/Button'
import { Icon } from '@independent-software/typeui/controls/Icon'

import { useNavigate } from 'react-router-dom';
import { ISigninRequest } from './ISigninRequest';
import { SigninForm } from './SigninForm';
import { useAuth } from '../../auth/useAuth';
import { SigninApi } from '../../api/signin/SigninApi';

// Adds a timeout to make sure that signin button animation actually gets seen:
const BUTTON_TIMEOUT = 250; // ms

const Signin = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [credentials, setCredentials] = React.useState<ISigninRequest>({ email: sessionStorage.getItem('email'), password: '' });
  const [loading, setLoading] = React.useState(false);
  const [valid, setValid] = React.useState(false);

  React.useEffect(() => {
    if(auth.access_token !== null) {
      navigate("/");
    }
  }, [auth.access_token]);

  const handleChange = (signinForm: ISigninRequest, forceupdate: boolean) => {
    setCredentials(signinForm);
  }  

  const handleValidate = (valid: boolean) => {
    setValid(valid);
  }  

  const handleSubmit = () => {
    if(loading) return;
    setLoading(true);
    setTimeout(() => {
      SigninApi.signin(credentials.email, credentials.password)
      .then(res => {
        auth.signin(res);
      })
      .catch(error => {
        setLoading(false);
        ToastService.toast(error.message);
      });
    }, BUTTON_TIMEOUT);
    // Adds a 250ms timeout to make sure that signin button
    // animation actually gets seen.    
  }    

  const handleForgot = () => {
    if(loading) return;
    navigate('/password/request');
  }

  return (
    <div>
      <Heading>Sign in</Heading>
      <SigninForm disabled={loading} data={credentials} onChange={handleChange} onValidate={handleValidate} onSubmit={handleSubmit}/>
      <div style={{display: 'flex', 'justifyContent': 'end'}}>
        {/* <Button basic secondary onClick={handleForgot}>
          Forgot password?
        </Button> */}
        <Button primary disabled={!valid} onClick={handleSubmit}>
          {loading  && <Icon loading name="circle-notch"/>}
          {!loading && "Sign in"}
          {loading  && "Signing in..."}
        </Button>
      </div>
    </div>
  )
}

const Heading = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #616A7C;
  margin-bottom: 8px;
`  

export { Signin }
