import * as React from 'react';

import Map, { Layer, MapLayerMouseEvent, MapboxGeoJSONFeature, MapboxMap, Source, ViewState, ViewStateChangeEvent } from 'react-map-gl';
import { ZoomInButton } from "@independent-software/mapbox-ext/controls/buttons/ZoomInButton";
import { ZoomOutButton } from "@independent-software/mapbox-ext/controls/buttons/ZoomOutButton";
import { FullscreenButton } from "@independent-software/mapbox-ext/controls/buttons/FullscreenButton";
import bbox from '@turf/bbox';

import { useFilter } from '../../contexts/useFilter';
import { MapLoader } from './MapLoader';
import { InfoBox } from './InfoBox';

const SmallMap = () => {
  const filter = useFilter();
  const map = React.useRef<MapboxMap>(null);
  const [viewState, setViewState] = React.useState<ViewState>({
    longitude: 0, 
    latitude: 0, 
    zoom: 3,
    bearing: 0,
    pitch: 0,
    padding: { top: 0, bottom: 0, right: 0, left: 0 }
  });
  const [hovered, setHovered] = React.useState<MapboxGeoJSONFeature>(null);

  const flyToMapData = () => {
    if(filter.mapdata == null) return;
    if(map.current == null) return;

    try {
      // Get bbox extent in minX, minY, maxX, maxY order
      const bounds = bbox(filter.mapdata);
      map.current.fitBounds(bounds as any, { padding: 32, duration: 1000 });
    } catch {}
  }

  React.useEffect(() => {
    flyToMapData();
  }, [filter.mapdata]);

  const handleLoad = (e: mapboxgl.MapboxEvent) => {
    map.current = e.target;
    flyToMapData();
  }

  const handleMove = (e: ViewStateChangeEvent) => setViewState(e.viewState);

  const handleMouseMove = (e: MapLayerMouseEvent) => {
    if(!map.current) return;

    // Clear previously-hovered features:
    if(hovered != null) map.current.setFeatureState(hovered, { hover: false });

    setHovered(null);
    if(!e.features || e.features.length == 0) return;

    // Store currently-hovered features:
    setHovered(e.features[0]);
    map.current.setFeatureState(e.features[0], { hover: true });
  }  

  return (
    <Map
      {...viewState}
      mapboxAccessToken="pk.eyJ1IjoibG9uZ2xpbmVlbnZpcm9ubWVudCIsImEiOiJjbGF0cHF1ZWUwM2l0M3FwcDcyN3B1YXpmIn0.snFi9yTPEZ5lfQxE3h3Epg"
      style={{width: '100%', height: '100%'}}
      logoPosition="bottom-right"
      interactiveLayerIds={['circle', 'fill', 'stroke', 'line']}
      attributionControl={false}
      scrollZoom={true}
      mapStyle='mapbox://styles/longlineenvironment/clo8shwwr00qq01qxbrvzaq6l'
      cursor={hovered != null ? 'pointer' : 'auto'}
      onLoad={handleLoad}
      onMove={handleMove}
      onMouseMove={handleMouseMove}
    >
      <FullscreenButton x={-20} y={100}/>
      <ZoomInButton  {...viewState} x={-20} y={20}/>
      <ZoomOutButton {...viewState} x={-20} y={60}/>

      {filter.mapLoading && <MapLoader/>}
      <Source type='geojson' generateId data={filter.mapdata ?? null}>
        <Layer 
          type="circle"
          id="circle"
          layout={{visibility: (filter.dataset.type == 'point' && !filter.mapLoading) ? 'visible' : 'none'}}
          paint={{
            "circle-radius": 8,
            "circle-color": [ 'case', ['boolean', ['feature-state', 'hover'], false], 'steelblue', '#fff' ],
            'circle-stroke-color': [ 'case', ['boolean', ['feature-state', 'hover'], false], 'steelblue', 'black' ],        
            "circle-stroke-width" : [ 'case', ['boolean', ['feature-state', 'hover'], false], 2, 1 ],        
            "circle-opacity": 0.75
          }}
        />
        <Layer 
          type="fill"
          id="fill"
          layout={{visibility: (filter.dataset.type == 'polygon' && !filter.mapLoading) ? 'visible' : 'none'}}
          paint={{
            "fill-color": [ 'case', ['boolean', ['feature-state', 'hover'], false], 'steelblue', '#fff' ],
            "fill-opacity": 0.75
          }}
        />
        <Layer 
          type="line"
          id="stroke"
          layout={{visibility: (filter.dataset.type == 'polygon' && !filter.mapLoading) ? 'visible' : 'none'}}
          paint={{
            "line-color": [ 'case', ['boolean', ['feature-state', 'hover'], false], 'steelblue', 'black' ],
            "line-width": [ 'case', ['boolean', ['feature-state', 'hover'], false], 2, 1 ],
          }}
        />
        <Layer 
          type="line"
          id="line"
          layout={{visibility: (filter.dataset.type == 'line' && !filter.mapLoading) ? 'visible' : 'none'}}
          paint={{
            "line-color": [ 'case', ['boolean', ['feature-state', 'hover'], false], 'steelblue', '#fff' ],
            "line-width": 3,
            "line-opacity": 0.75
          }}
        />

        {hovered != null && <InfoBox properties={hovered.properties}/>}

      </Source>
    </Map>    
  )
}

export { SmallMap }
