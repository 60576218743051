import * as React from 'react';
import { IProjectProxy } from '../api/project/IProjectProxy';
import { IProject } from '../api/project/IProject';
import { IDataset } from '../api/dataset/IDataset';
import { IUser } from '../api/user/IUser';

interface IFilter {
  projects: IProjectProxy[];
  project: IProject;
  dataset: IDataset;
  users: IUser[];
  mapdata: any;
  mapLoading: boolean;
  setProject: (project: IProject) => void;
  loadProject: (uuid: string) => void;
  addProject: (project: IProject) => void;
  editProject: (project: IProject) => void;
  removeProject: (project: IProject) => void;
  setDataset: (dataset: IDataset) => void;
  addUser: (user: IUser) => void;
  editUser: (user: IUser) => void;
  removeUser: (user: IUser) => void;
}

const FilterContext = React.createContext<IFilter>({
  projects: [],
  project: null,
  dataset: null,
  users: [],
  mapdata: null,
  mapLoading: false,
  setProject: () => {},
  loadProject: () => {},
  addProject: () => {},
  editProject: () => {},
  removeProject: () => {},
  setDataset: () => {},
  addUser: () => {},
  editUser: () => {},
  removeUser: () => {}
});

export { FilterContext, IFilter }
