import * as React from 'react';

import { Button, Dialog, Icon, ToastService } from '@independent-software/typeui/controls';

import { IUser } from '../../../api/user/IUser';
import { useAuth } from '../../../auth/useAuth';
import { UserApi } from '../../../api/user/UserApi';
import { EditUserForm } from '../forms/EditUserForm';

interface IProps {
  user: IUser;
  /** Is the dialog currently open? */
  open: boolean;
  /** Fired when dialog is closed. */
  onClose: () => void;
  /** Fired when user has saved successfully. */
  onSave: (user: IUser) => void;
  /** Fired when delete dialog must be initiated. */
  onDelete: () => void;
}

const EditUserDialog = (props: IProps) => {
  const auth = useAuth();
  // User being worked on:
  const [user, setUser] = React.useState<IUser>({ email: null, manager: false });
  // Is form valid?
  const [valid, setValid] = React.useState(false);
  // Currently saving? Will disable Save button.
  const [saving, setSaving] = React.useState(false);
  // Save error? Causes XHR dialog to appear.
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if(props.user != null) setUser(props.user);
  }, [props.user]);

  const handleChange = (value: any) => {
    setUser(value);
  }

  const handleValidate = (valid: boolean) => {
    setValid(valid);
  }

  const handleSave = () => {
    setSaving(true);
    setError(null);
    UserApi.update(user, auth.access_token)
      .then(res => {
        ToastService.toast("User updated.");
        props.onSave(res);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setSaving(false));
  }

  const handleClose = () => {
    if(saving) return;
    setError(null);
    props.onClose();
  }

  return (
    <>
      <Dialog open={props.open && error === null} onClose={handleClose}>
        <Dialog.Header>
          Edit user
        </Dialog.Header>
        <EditUserForm user={user} onChange={handleChange} onValidate={handleValidate}/>
        <Dialog.Footer align='space-between'>
          <div>
            <Button negative disabled={saving}  onClick={props.onDelete}>Delete user</Button>
          </div>
          <div>
            <Button secondary disabled={saving} onClick={props.onClose}>Cancel</Button>
            <Button primary disabled={!valid || saving} onClick={handleSave}>
              {saving  && <Icon loading name="circle-notch"/>}
              {!saving && "Update user"}
              {saving  && "Updating..."}                   
            </Button>
          </div>
        </Dialog.Footer>      
      </Dialog>
      <Dialog.Xhr open={error != null} error={error} onRetry={handleSave} onClose={() => setError(null)}/>
    </>
  )
}

export { EditUserDialog }
