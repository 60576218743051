import * as React from 'react';
import styled from 'styled-components';

import { Gravatar } from '@independent-software/typeui/controls';
import { Size } from '@independent-software/typeui/controls/Types';
import { useAuth } from '../../auth/useAuth';

interface IProps {
  /**
   * Gravatar image size. If not specified, uses `avatar`.
   * @default avatar
   */
  size?: Size;
  /** onClick handler, if any. */
  onClick?: () => void;
}

//
// A Gravatar that gets a gold circle if the user is a manager.
// 

const ManagerGravatar = (props: IProps) => {
  const auth = useAuth();
  if(auth.manager) {
    return (
      <ManagerCircle>
        <Gravatar email={auth.email} {...props}/>
      </ManagerCircle>
    )
  } else {
    return (
      <Gravatar email={auth.email} {...props}/>
    );
  }
}

const ManagerCircle = styled.div`
  border-radius: 50%;
  border: solid 1px gold;
`

export { ManagerGravatar }
