import * as React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';

import { Signin } from './routes/signin/Signin';
import { PublicRoute } from './routes/PublicRoute';
import { ProtectedRoute } from './routes/ProtectedRoute';
import { NoMatch } from './routes/nomatch/NoMatch';
import { Home } from './routes/home/Home';
import { Users } from './routes/users/Users';
import { Log } from './routes/log/Log';

// Router documentation:
// Intro: https://www.robinwieruch.de/react-router/
// Authentication: https://www.robinwieruch.de/react-router-authentication/

const Router = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="signin" element={<PublicRoute><Signin/></PublicRoute>}/>
        <Route element={<ProtectedRoute/>}>
          <Route path="" element={<Home/>}/>
          <Route path="home" element={<Home/>}/>
          <Route path="users" element={<Users/>}/>
          <Route path="log" element={<Log/>}/>
        </Route>
        <Route path="*" element={<PublicRoute><NoMatch/></PublicRoute>}/>
      </Routes>
    </HashRouter>
  );
}

export { Router }
