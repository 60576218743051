import * as React from 'react';
import styled from 'styled-components';

import { Button, Flex, Icon, Label, List, Message, Panel } from '@independent-software/typeui/controls';

import { UI } from '../../controls/ui/UI';
import { useFilter } from '../../contexts/useFilter';
import { useAuth } from '../../auth/useAuth';
import { DatasetState } from '../../controls';
import { DeleteDatasetDialog } from './dialogs/DeleteDatasetDialog';
import { EditDatasetDialog } from './dialogs/EditDatasetDialog';
import { UploadDatasetDialog } from './dialogs/UploadDatasetDialog';
import { IDataset } from '../../api/dataset/IDataset';
import { UUID } from './UUID';
import { Datum } from '@independent-software/typeui/formatters/Datum';
import { SmallMap } from './SmallMap';
import { DatasetApi } from '../../api/dataset/DatasetApi';
import FileSaver from 'file-saver';

type TActivity = 'edit_dataset' | 'delete_dataset' | 'upload';

interface IProps {


}

const Dataset = (props: IProps) => {
  const auth = useAuth();
  const filter = useFilter();

  const [activity, setActivity] = React.useState<TActivity>(null);
  const [panelOpen, setPanelOpen] = React.useState(false);

  const handleEditDataset = (dataset: IDataset) => {
    filter.setProject({ 
      ...filter.project, 
      datasets: [...filter.project.datasets.filter(d => d.uuid != dataset.uuid), dataset]
    });
    filter.setDataset(dataset);
    setActivity(null);
  }

  const handleUploadDataset = (dataset: IDataset) => {
    // Update selected dataset:
    dataset.state = 'ok';
    filter.setDataset({...dataset});
    // Make sure update is reflected in list of datasets:
    filter.setProject({ 
      ...filter.project, 
      datasets: [...filter.project.datasets.filter(d => d.uuid != dataset.uuid), dataset]
    });
    setActivity(null);
  }

  const handleDeleteDataset = () => {
    filter.setProject( { ...filter.project, datasets: filter.project.datasets.filter(d => d.uuid != filter.dataset.uuid) });
    filter.setDataset(null);
    setActivity(null);
  }

  const handleDownload = () => {
    DatasetApi.download(filter.dataset.uuid, auth.access_token)
    .then((res) => {
      var blob = new Blob([JSON.stringify(res)], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, `${filter.dataset.name}.geojson`);
    });
  }

  // Don't show anything if no project is selected.
  if(filter.project == null) return null;

  // Don't show anything if the current project has no datasets.
  if(filter.project.datasets.length == 0) return null;
  
  // Show a hint if no dataset is currently selected.
  if(filter.dataset == null) {
    return (
      <Padding>
        <Message type='info'>
          <Message.Content>
            Please select a dataset.
          </Message.Content>
        </Message>
      </Padding>
    );
  }

  return (
    <Wrapper>
      <Padding>
        <Header>
          <UI.Title>{filter.dataset.name}</UI.Title>
          <Flex.Quick>
            <UUID/>
            <Label size="small" basic>{filter.dataset.type}</Label>
            <DatasetState state={filter.dataset.state}/>
            <Label size="small" basic>{filter.dataset.requests} requests</Label>
            {filter.dataset.state == 'ok' && <Label size="small" color="#333" onClick={handleDownload}>download</Label>}
          </Flex.Quick>
        </Header>

        {filter.dataset.description && <>
          <UI.Subtitle>Description</UI.Subtitle>
          <List divided relaxed>
            <List.Item>
              {filter.dataset.description}
            </List.Item>
          </List>
        </>}
      </Padding>

      <PropertiesTitle>
        Properties <Label size="tiny">{filter.dataset.properties.length}</Label>
      </PropertiesTitle>

      <PropertiesBox>
        <List divided relaxed>
          {filter.dataset.properties.map(p => 
            <List.Item key={p.name}>{p.name}</List.Item>
          )}
          {filter.dataset.properties.length == 0 && 
            <List.Item>
              <div style={{color: '#888'}}>(This dataset has no properties.)</div>
            </List.Item>}
        </List>
      </PropertiesBox>

      {filter.dataset.state == 'ok' && <MapBox>
        <SmallMap/>
      </MapBox>}

      <DatesBox>
        {filter.dataset.created_at && <>
          <Date>
            Created&nbsp;<Datum.Distance value={filter.dataset.created_at}/>&nbsp;by  {filter.dataset.created_by.email}
          </Date>
        </>}
        {filter.dataset.updated_at && <>
          <Date>
            Updated&nbsp;<Datum.Distance value={filter.dataset.updated_at}/>&nbsp;by  {filter.dataset.updated_by.email}
          </Date>
        </>}
      </DatesBox>

      <UI.Footer>
        <div></div>
        <div style={{display:'flex', gap: '8px'}}>
          <Button primary onClick={() => setActivity('upload')}><Icon name="cloud-upload"/> Upload data</Button>
          {auth.manager == true && <>
            <div style={{position: 'relative'}}>
            <Button icon onClick={() => setPanelOpen(!panelOpen)}><Icon name="edit"/></Button>
            <Panel open={panelOpen} onClose={() => setPanelOpen(false)} padded>
              <div style={{display: 'flex', gap: '8px'}}>
                <Button onClick={() => setActivity('edit_dataset')}><Icon name="edit"/> Edit dataset</Button>
                <Button negative onClick={() => setActivity('delete_dataset')}><Icon name="trash"/> Delete dataset</Button>              
              </div>
            </Panel>                
            </div>
          </>}
        </div>
      </UI.Footer>

      <EditDatasetDialog dataset={filter.dataset} open={activity === 'edit_dataset'} onClose={() => setActivity(null)} onSave={handleEditDataset}/>
      <DeleteDatasetDialog dataset={filter.dataset} open={activity === 'delete_dataset'} onClose={() => setActivity(null)} onDelete={handleDeleteDataset}/>
      <UploadDatasetDialog dataset={filter.dataset} open={activity === 'upload'} onClose={() => setActivity(null)} onSave={handleUploadDataset}/>      

    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Padding = styled.div`
  padding: 32px 32px 16px 32px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;  
`

const Section = styled.div`
  margin-bottom: 16px;
`

const DatesBox = styled.div`
  padding: 8px 32px 8px 32px;
`

const Date = styled.div`
  text-align: right;
  color: #666;  
  font-size: 80%;
`

const PropertiesTitle = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  font-weight: 500;
`

const PropertiesBox = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding-left: 32px;
  padding-right: 32px;  
  margin-bottom: 16px;
`

const MapBox = styled.div`
  height: 35%;
`

export { Dataset }
