import * as React from 'react';
import { Label } from '@independent-software/typeui/controls';

interface IProps { 
  state: string;
}

const DatasetState = (props: IProps) => {
  if(props.state == 'error') return <Label size="small" basic color='red'>Error</Label>
  if(props.state == 'missing') return <Label size="small" basic color='red'>Missing</Label>
  return <Label size="small" basic color='green'>OK</Label>
}

export { DatasetState }
