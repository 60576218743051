import * as React from 'react';
import styled from 'styled-components';

import { Button, Dropdown, Flex, Form, Icon, Input, List, Textarea } from '@independent-software/typeui/controls';
import { UI } from '../../../controls/ui/UI';
import { IDataset } from '../../../api/dataset/IDataset';

interface IProps {
  dataset: IDataset;
  onChange: (dataset: IDataset) => void;
  onValidate: (valid: boolean) => void;
}

//
// Reusable dataset form for adding and editing datasets
//

const DatasetForm = (props: IProps) => {
  // Property name being edited
  const [property, setProperty] = React.useState(null);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if(e.charCode != 13) return;
    if(!property || property.trim().length == 0) return;
    handleAddProperty();
  }  

  const handleChange = (value: IDataset) => {
    props.onChange({...value});
  }

  const handleValidate = (valid: boolean) => {
    props.onValidate(valid);
  }

  const handleChangeProperty = (value: string) =>{
    setProperty(value);
  }

  const handleAddProperty = () => {
    // Property already exists?
    if(props.dataset.properties.find(p => p.name == property)) return;
    const dataset = {...props.dataset, properties: [...props.dataset.properties, { name: property }]};
    setProperty(null);
    props.onChange(dataset);
  }

  const handleDeleteProperty = (index: number) => {
    const dataset: IDataset = {
      ...props.dataset, 
      properties: [...props.dataset.properties.filter((_, idx) => idx !== index)]
    };
    props.onChange(dataset);
  }  

  return (
    <DialogPadding>
      <Flex.Columns count={2}>
        <Form data={props.dataset} onChange={handleChange} onValidate={handleValidate}>
          <Form.Field
            name="name"
            value={props.dataset.name}
            label="Dataset name"
            hint="Please provide a short name for this dataset."
            required={{message: "Dataset name is required."}}
            minLength={{length: 3, message: "Dataset name must be at least 3 characters."}}
            maxLength={{length: 255, message: "Dataset name must not be longer than 255 characters."}}
            control={<Input fluid type="text" placeholder="Dataset name"/>}
          />
          <Form.Field 
            name="type"
            value={props.dataset.type}
            label="Feature type"
            hint="Please select a feature type."
            required={{ message: "Feature type is required. "}}
            control={<Dropdown fluid data={['point', 'polygon', 'line']} label={(item) => item} placeholder="Feature type">
              <Dropdown.Column>{(item: any) => item}</Dropdown.Column>
            </Dropdown>}
          />
          <Form.Field 
            name="description"
            value={props.dataset.description}
            label="Dataset description"
            hint="Please provide a brief description of this dataset."
            control={<Textarea fluid placeholder="Description"/>}
          />        
        </Form>
        
        <PropertiesBorder>
          <UI.Subtitle>Properties</UI.Subtitle>
          <Scroller>
            <List divided relaxed>
              {props.dataset.properties.map((p, idx) => 
                <List.Item key={idx}>
                  <List.Content>
                    {p.name}
                  </List.Content>
                  <List.Content align="bottom">
                    <Button negative icon size="tiny" onClick={() => handleDeleteProperty(idx)}><Icon name="trash"/></Button>
                  </List.Content>
                </List.Item>)}
            </List>
            {props.dataset.properties.length === 0 && <None>(none)</None>}
          </Scroller>
          <Flex.Quick>
            <div onKeyPress={handleKeyPress}>
              <Input fluid clearable type="text" value={property} onChange={handleChangeProperty}/>
            </div>
            <Button disabled={!property || property.trim().length == 0} size="small" onClick={handleAddProperty}>Add</Button>
          </Flex.Quick>
        </PropertiesBorder>

      </Flex.Columns>
    </DialogPadding>    
  )
}

const DialogPadding = styled.div`
  padding: 16px;
`

const PropertiesBorder = styled.div`
  border-left: solid 1px #ddd;
  padding-left: 32px;
`

const Scroller = styled.div`
  height: 250px;
  overflow-y: scroll;
`

const None = styled.div`
  color: #888;
`

export { DatasetForm }
