import styled from "styled-components"

const Footer = styled.div`
  position: relative;
  background: white;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  border-top: solid 1px #ddd;
  background: #eee;
`

export { Footer }
