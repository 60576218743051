import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  className?: string;
  properties: { [key: string]: string }
}

const InfoBoxBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <table>
        <tbody>
          {Object.entries(props.properties).map((kv, idx) => {
            return (<tr key={idx}>
              <td>
                {kv[0]}
              </td>
              <td>
                {kv[1]}
              </td>
            </tr>)
          })}
        </tbody>
      </table>
    </div>
  )
}

const InfoBox = styled(InfoBoxBase)`
  position: absolute;
  left: 20px;
  bottom: 20px;
  border-radius: 8px;
  padding: 8px;
  color: white;
  background: rgba(0,0,0,0.5);
  border: solid 2px #fff;
  pointer-events: none;
  max-width: 90%;

  td {
    padding-right: 8px;
  }
  td:first-child {
    color: #ddd;
  }
  td:last-child {
    color: #fff;
  }
`

export { InfoBox }

