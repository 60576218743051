import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Icon, Input, Label } from '@independent-software/typeui/controls';
import { Datum } from '@independent-software/typeui/formatters/Datum';

import { useAuth } from '../../auth/useAuth';
import { useFilter } from '../../contexts/useFilter';
import { Table } from '../../controls/Table';
import { UI } from '../../controls/ui/UI';
import { IUser } from '../../api/user/IUser';
import { AddUserDialog } from './dialogs/AddUserDialog';
import { EditUserDialog } from './dialogs/EditUserDialog';
import { DeleteUserDialog } from './dialogs/DeleteUserDialog';

type TActivity = 'add_user' | 'edit_user' | 'delete_user';

const Users = () => {
  const auth = useAuth();
  const filter = useFilter();
  const navigate = useNavigate();
  const [activity, setActivity] = React.useState<TActivity>(null);
  const [user, setUser] = React.useState<IUser>(null);
  const [q, setQ] = React.useState<string>(null);

  const handleChangeQ = (value: string) => {
    setQ(value);
  }

  const handleSelectUser = (user: IUser) => {
    setUser(user);
    setActivity('edit_user');
  }

  const handleAddUser = (user: IUser) => {
    filter.addUser(user);
    setActivity(null);
  }

  const handleEditUser = (user: IUser) => {
    filter.editUser(user);
    setActivity(null);
  }

  const handleDeleteUser = () => {
    filter.removeUser(user);
    setActivity(null);
  }

  const handleGoHome = () => {
    navigate("/");
  }

  const filteredUsers = filter.users.filter(p => q == null || p.email.toLowerCase().indexOf(q.toLowerCase()) != -1);

  return (
    <>
      <UI.Header>
        <Flex.Quick>
          <Icon name="chevron" rotated={180} bordered inverted onClick={handleGoHome} title="Go to projects"/>          
          <UI.Title>Users</UI.Title>
          <Label size="tiny">{filter.users.length}</Label>
        </Flex.Quick>
        <Input clearable value={q} type="text" placeholder="Type to search users" onChange={handleChangeQ}/>
      </UI.Header>
      <UI.Body>
        <Table sort="email" data={filteredUsers} loading={false} hover striped onClick={handleSelectUser}>
          <Table.Column weight={3} name="Email" sort="email" align="left">
            {(item: IUser) => item.email}
          </Table.Column>
          <Table.Column weight={1} name="Last login" sort="login_at" align="left">
            {(item: IUser) => item.login_at ? <Datum.Distance value={item.login_at}/> : 'never' }
          </Table.Column>          
          <Table.Column weight={1} name="Manager" sort="manager" align="left">
            {(item: IUser) => item.manager ? 'Yes' : 'No'}
          </Table.Column>                    
        </Table>
      </UI.Body>
      <UI.Footer>
        <div></div>
        {auth.manager == true && <Button primary onClick={() => setActivity('add_user')}>Add user</Button>}
      </UI.Footer>

      <AddUserDialog open={activity == 'add_user'} onClose={() => setActivity(null)} onSave={handleAddUser}/>
      <EditUserDialog user={user} open={activity == 'edit_user'} onClose={() => setActivity(null)} onSave={handleEditUser} onDelete={() => setActivity('delete_user')}/>
      <DeleteUserDialog user={user} open={activity == 'delete_user'} onClose={() => setActivity(null)} onDelete={handleDeleteUser}/>
    </>
  )
}

export { Users }

