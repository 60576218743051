import * as React from 'react';
import { ISigninResponse } from '../api/signin/SigninResponse';

interface IAuth {
  uuid: string;
  access_token: string;
  email: string;
  manager: boolean;
  signin: (data: ISigninResponse) => void;
  signout: () => void;
}

const AuthContext = React.createContext<IAuth>({
  uuid: null,
  access_token: null,
  email: null,
  manager: false,
  signin: () => {},
  signout: () => {}
});

export { AuthContext, IAuth }
