import { Api } from "../Api";
import { IAccount } from "./IAccount";
import { IUser } from "./IUser";

class UserApi {

  static list = async (access_token: string, signal?: AbortSignal): Promise<IUser[]> => {
    return Api.Get<IUser[]>(`users`, access_token, signal);
  }

  static get = async (uuid: string, access_token: string, signal?: AbortSignal): Promise<IUser> => {
    return Api.Get<IUser>(`user/${uuid}`, access_token, signal);
  }

  static create = async (user: IUser, access_token: string, signal?: AbortSignal): Promise<IUser> => {
    return Api.Post<IUser>(`user`, user, access_token, signal);
  }

  static update = async (user: IUser, access_token: string, signal?: AbortSignal): Promise<IUser> => {
    return Api.Put<IUser>(`user`, user, access_token, signal);
  }

  static delete = async (uuid: string, access_token: string, signal?: AbortSignal) => {
    return Api.Delete(`user/${uuid}`, access_token, signal);
  }

  static change_password = async(account: IAccount, access_token: string, signal?: AbortSignal) => {
    return Api.Post(`account`, account, access_token, signal);
  }

}

export { UserApi }