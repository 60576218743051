import * as React from 'react';
import { StyleReset, StyleBase, Theme } from '@independent-software/typeui/styles';
import { ThemeProvider } from 'styled-components';
import { IThemeInterface } from '@independent-software/typeui/styles/Theme';
import { ToastContainer } from '@independent-software/typeui/controls/Toast';
import { Auth } from './auth/Auth';
import { Router } from './Router';

const MyTheme: IThemeInterface = {
  ...Theme,
  radius: 4
}

const Main = () => {
  return (
    <>
      <StyleReset/>
      <StyleBase/>
      <ThemeProvider theme={MyTheme}>
        <ToastContainer maxToasts={5} horizontalAlign='left' verticalAlign='bottom' horizontalOffset={32} verticalOffset={75}/>
        <Auth>
          <svg><use xlinkHref={"sprites.svg#LOGO+TXT_WHITE"}></use></svg>
          <Router/>
        </Auth>
      </ThemeProvider>
    </>
  );
}

export { Main }
