import * as React from 'react';
import { Button, Dialog, Form, Icon, Input, ToastService } from '@independent-software/typeui/controls';

import { useAuth } from '../../../auth/useAuth';
import { IProject } from '../../../api/project/IProject';
import { ProjectApi } from '../../../api/project/ProjectApi';

interface IProps {
  /** Project to be deleted. */
  project: IProject;
  /** Is dialog currently open? */
  open: boolean;
  /** Fired when dialog is closed. */
  onClose: () => void;
  /** Fired when deletion is confirmed. */
  onDelete: (project: IProject) => void;
}

const DeleteProjectDialog = (props: IProps) => {
  const auth = useAuth();
  const [error, setError] = React.useState(null);
  const [deleting, setDeleting] = React.useState(false);
  const [email, setEmail] = React.useState(null);

  // When dialog opens/closes, clear email.
  React.useEffect(() => {
    setEmail(null);
  }, [props.open]);  

  const handleDelete = () => {
    setError(null);
    setDeleting(true);
    ProjectApi.delete(props.project.uuid, email, auth.access_token)
    .then(() => {
      ToastService.toast("Project deleted.");
      props.onDelete(props.project)
    })
    .catch((err) => {
      setError(err);
    })
    .finally(() => setDeleting(false));
  }

  const handleCancel = () => {
    if(deleting) return;
    setError(null);
    props.onClose();
  }

  return (
    <>
      <Dialog open={props.open && error === null} onClose={handleCancel}>
        <Dialog.Header>
          Delete project?
        </Dialog.Header>
        <Dialog.Content>
          <p>This action cannot be undone.</p>
          <Form.Uncontrolled hint="Please type your email address for security.">
            <Input fluid type="text" value={email} onChange={setEmail}/>
          </Form.Uncontrolled>
        </Dialog.Content>
        <Dialog.Footer align='space-between'>
          <Button secondary disabled={deleting} onClick={handleCancel}>Cancel</Button>
          <Button negative disabled={deleting || !email || email.trim() == ""} onClick={handleDelete}>
            {deleting  && <Icon loading name="circle-notch"/>}
            {!deleting && "Delete project"}
            {deleting  && "Deleting..."}                 
          </Button>
        </Dialog.Footer>
      </Dialog>
      <Dialog.Xhr open={error != null} error={error} onRetry={handleDelete} onClose={handleCancel}/>
    </>
  )
}

export { DeleteProjectDialog }
