import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

const PublicRouteBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Page>
        <Content>
          <Box>
            <LogoBox>
              <Logo><use xlinkHref={"sprites.svg#logo-text-blue"}></use></Logo>
            </LogoBox>
            {props.children}
          </Box>
        </Content>
      </Page>
    </div>
  );
}

const Content = styled.div`
  position: absolute;
  width: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Box = styled.div`
  background: #fff;
  padding: 25px 25px 25px 25px;
  border-radius: 4px;
`

const Page = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: conic-gradient(from 0deg at 0 0, #071119 0deg, #071119 90deg, #243961 180deg, #243961 -1deg);
  background-blend-mode: multiply;
`

const LogoBox = styled.div`
  padding-bottom: 5px;
  border-bottom: solid 1px #E8E8E8;
  margin-bottom: 10px;
`

const Logo = styled.svg`
  width: 200px;
  height: 50px;
`

const PublicRoute = styled(PublicRouteBase)`
  position:    absolute;
  left:        0;
  top:         0;
  width:       100vw;
  height:      100vh;
  overflow:    hidden;  
`

export { PublicRoute }
