import { Icon } from '@independent-software/typeui/controls';
import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
}

const MapLoaderBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Icon color="white" size="large" name="circle-notch" loading/>
    </div>
  )
}

const MapLoader = styled(MapLoaderBase)`
  position: absolute;
  left: 10px;
  top: 10px;
`

export { MapLoader}
