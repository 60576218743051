import * as React from 'react';
import styled, { css } from "styled-components"

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Is body indented? */
  indented?: boolean;
}

const BodyBase = (props: IProps) => 
  <div className={props.className}>
    {props.children}
  </div>

const Body = styled(BodyBase)`
  position: relative;
  flex: 1;
  overflow-y: auto;
  ${p => p.indented && css`
    padding: 32px;
  `}
`

export { Body } 
