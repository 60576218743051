import * as React from 'react';
import { IDataset } from '../../../api/dataset/IDataset';
import { Button, Dialog, Icon, ToastService } from '@independent-software/typeui/controls';
import { DatasetApi } from '../../../api/dataset/DatasetApi';
import { useAuth } from '../../../auth/useAuth';

interface IProps {
  /** Dataset to be deleted. */
  dataset: IDataset;
  /** Is dialog currently open? */
  open: boolean;
  /** Fired when dialog is closed. */
  onClose: () => void;
  /** Fired when deletion is confirmed. */
  onDelete: () => void;
}

const DeleteDatasetDialog = (props: IProps) => {
  const auth = useAuth();
  const [error, setError] = React.useState(null);
  const [deleting, setDeleting] = React.useState(false);

  const handleDelete = () => {
    setError(null);
    setDeleting(true);
    DatasetApi.delete(props.dataset.uuid, auth.access_token)
    .then(() => {
      ToastService.toast("Dataset deleted.");
      props.onDelete()
    })
    .catch((err) => {
      setError(err);
    })
    .finally(() => setDeleting(false));
  }

  const handleCancel = () => {
    if(deleting) return;
    setError(null);
    props.onClose();
  }

  return (
    <>
      <Dialog open={props.open && error === null} onClose={handleCancel}>
        <Dialog.Header>
          Delete dataset?
        </Dialog.Header>
        <Dialog.Content>
          Are you sure you wish to delete this dataset? This action cannot be 
          undone.
        </Dialog.Content>
        <Dialog.Footer align='space-between'>
          <Button secondary disabled={deleting} onClick={handleCancel}>Cancel</Button>
          <Button negative disabled={deleting} onClick={handleDelete}>
            {deleting  && <Icon loading name="circle-notch"/>}
            {!deleting && "Delete dataset"}
            {deleting  && "Deleting..."}                
          </Button>
        </Dialog.Footer>
      </Dialog>
      <Dialog.Xhr open={error != null} error={error} onRetry={handleDelete} onClose={() => handleCancel}/>
    </>
  )

}

export { DeleteDatasetDialog }
