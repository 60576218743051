import axios from 'axios';
import { App } from '../../App';
import { ISigninResponse } from './SigninResponse';
import { Api } from '../Api';

class SigninApi {

  /**
   * Sign in with email/password, and return ISigninResponse
   * @param email Email to login with
   * @param password Password to login with
   * @returns ISigninResponse contains user ID (= email), plus authorities.
   */
  static signin = async (email: string, password: string): Promise<ISigninResponse> => {
    return Api.Post<ISigninResponse>('signin', { email: email, password: password }, null)
    .catch((error) => {
      if(error.response) {
        throw new Error((error.response.data as any).message + ".");
      } else if(error.request) {
        throw new Error("No server response was received.");
      } else {
        throw new Error("There was a problem sending your request.");
      }
    });
  }
}

export { SigninApi }
