import * as React from 'react';
import { Button, Dialog, ToastService } from "@independent-software/typeui/controls"

import { IAccount } from '../../../api/user/IAccount';
import { AccountForm } from '../forms/AccountForm';
import { UserApi } from '../../../api/user/UserApi';
import { useAuth } from '../../../auth/useAuth';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const AccountDialog = (props: IProps) => {
  const auth = useAuth();
  const [account, setAccount] = React.useState<IAccount>({ password: null, new_password: null});
  const [error, setError] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const [valid, setValid] = React.useState(false);

  const handleChange = (value: any) => {
    setAccount(value);
  }

  const handleValidate = (valid: boolean) => {
    setValid(valid);
  }

  const handleSave = () => {
    setSaving(true);
    setError(null);
    UserApi.change_password(account, auth.access_token)
    .then((res) => {
      ToastService.toast("Password changed.");
      props.onClose()
    })
    .catch(err => setError(err))
    .finally(() => setSaving(false));
  }

  const handleClose = () => {
    setError(null);
    props.onClose();
  }

  return (
    <>
      <Dialog open={props.open && error === null} onClose={handleClose}>
        <Dialog.Header>
          Change password
        </Dialog.Header>
        <AccountForm account={account} onChange={handleChange} onValidate={handleValidate}/>
        <Dialog.Footer align='space-between'>
          <Button secondary disabled={saving} onClick={props.onClose}>Cancel</Button>
          <Button primary disabled={!valid || saving} onClick={handleSave}>Change password</Button>
        </Dialog.Footer>      
      </Dialog>
      <Dialog.Xhr open={error != null} error={error} onRetry={handleSave} onClose={() => setError(null)}/>  
    </>
  )
}

export { AccountDialog }
