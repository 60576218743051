import * as React from 'react';
import styled from 'styled-components';

import { Button, Icon, Message } from '@independent-software/typeui/controls';

import { useAuth } from '../../auth/useAuth';
import { useFilter } from '../../contexts/useFilter';
import { Table } from '../../controls/Table';
import { IDataset } from '../../api/dataset/IDataset';
import { DatasetState } from '../../controls';
import { UI } from '../../controls/ui/UI';
import { AddDatasetDialog } from './dialogs/AddDatasetDialog';

type TActivity = 'add_dataset';

interface IProps {
  onSelect: (dataset: IDataset) => void;
}

const DatasetList = (props: IProps) => {
  const auth = useAuth();
  const filter = useFilter();
  const [activity, setActivity] = React.useState<TActivity>(null);

  const handleAddDataset = (dataset: IDataset) => {
    filter.setProject({ ...filter.project, datasets: [...filter.project.datasets, dataset]});
    filter.setDataset(dataset);
    setActivity(null);
  }

  // Take filter's dataset list, and mark each dataset with whether it's 
  // selected or not. This is used for a "selected" row in the table.
  const datasetsWithSelection = filter.project.datasets.map(d => { 
    return { ...d, selected: filter.dataset && d.uuid === filter.dataset.uuid }});

  return (
    <Wrapper>

      {filter.project.datasets.length == 0 &&
        <Padding>
          <Message type='info'>
            <Message.Content>
              This project contains no datasets.
            </Message.Content>
          </Message>
        </Padding>}

      {filter.project.datasets.length > 0 && 
        <Table sort="name" data={datasetsWithSelection} hover striped onClick={props.onSelect}>
          <Table.Column weight={3} name="Name" sort="name" align="left">
            {(item: IDataset) => item.name}
          </Table.Column>
          <Table.Column weight={1} name="State" align="left">
            {(item: IDataset) => <DatasetState state={item.state}/>}
          </Table.Column>
        </Table>}

      <UI.Footer>
        {auth.manager == true && 
          <Button primary onClick={() => {setActivity('add_dataset')}}><Icon name="plus"/> Add dataset</Button>}
      </UI.Footer>

      <AddDatasetDialog projectUUID={filter.project ? filter.project.uuid : null} open={activity === 'add_dataset'} onClose={() => setActivity(null)} onSave={handleAddDataset}/>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Padding = styled.div`
  padding: 16px;
  flex: 1;
`

export { DatasetList }
