import * as React from 'react';

import { Button, Label, Panel, ToastService } from '@independent-software/typeui/controls';

import { useFilter } from '../../contexts/useFilter';
import styled from 'styled-components';

//
// Shows a small "UUID" button. Expands into a panel when clicked, showing
// the current dataset's UUID, which can be copied to the clipboard.
//

const UUID = () => {
  const filter = useFilter();
  const [open, setOpen] = React.useState(false);

  const handleCopy = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(filter.dataset.uuid);
      ToastService.toast("UUID copied to clipboard.");
    }
  }  
  
  return (
    <div style={{position:'relative'}}>
      <Label size="small" color="black" onClick={() => setOpen(!open)}>UUID</Label>
      <Panel open={open} padded onClose={() => setOpen(false)}>
        <UuidText>
          {filter.dataset.uuid}
        </UuidText>
        <ButtonWrapper>
          <Button size="tiny" onClick={handleCopy}>Copy</Button>
        </ButtonWrapper>
      </Panel>
    </div>
  );
}

const UuidText = styled.div`
  font-size: 11px;  
`

const ButtonWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`

export { UUID }
