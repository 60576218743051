import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../auth/useAuth';
import { Filter } from '../contexts/Filter';

interface IProps {
  className?: string;
}

const ProtectedRouteBase = (props: IProps) => {
  const auth = useAuth();

  if (auth.access_token == null) {
    return <Navigate to="/signin" replace />
  }

  return (
    <div className={props.className}>
      <Filter>
        <Outlet/>
      </Filter>
    </div>
  );
}

const ProtectedRoute = styled(ProtectedRouteBase)`
  position:    absolute;
  left:        0;
  top:         0;
  width:       100vw;
  height:      100vh;
  overflow:    hidden;  
  display:     flex;
  flex-direction: column;
`

export { ProtectedRoute }
