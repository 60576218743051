import { Api } from "../Api";
import { IProject } from "./IProject";
import { IProjectProxy } from "./IProjectProxy";

class ProjectApi {

  static list = async (access_token: string, signal?: AbortSignal): Promise<IProjectProxy[]> => {
    return Api.Get<IProjectProxy[]>(`projects`, access_token, signal);
  }

  /**
   * Create a new project. A project only has a name.
   * @param name Project name
   * @param access_token 
   * @param signal 
   * @returns 
   */
  static create = async(project: IProject, access_token: string, signal?: AbortSignal): Promise<IProject> => {
    return Api.Post<IProject>(`project`, project, access_token, signal);
  }

  static get = async(uuid: string, access_token: string, signal?: AbortSignal): Promise<IProject> => {
    return Api.Get<IProject>(`project/${uuid}`, access_token, signal);
  }

  static update = async(project: IProject, access_token: string, signal?: AbortSignal): Promise<IProject> => {
    return Api.Put<IProject>(`project`, project, access_token, signal);
  }

  static delete = async(uuid: string, email: string, access_token: string, signal?: AbortSignal) =>{
    return Api.Delete(`project/${uuid}/${email}`, access_token, signal);
  }

}

export { ProjectApi }