import * as React from 'react';

import { Button, Dialog, Icon, ToastService } from '@independent-software/typeui/controls';

import { useAuth } from '../../../auth/useAuth';
import { IProject } from '../../../api/project/IProject';
import { ProjectApi } from '../../../api/project/ProjectApi';
import { ProjectForm } from '../forms/ProjectForm';

interface IProps {
  /** Is the dialog currently open? */
  open: boolean;
  /** Fired when dialog is closed. */
  onClose: () => void;
  /** Fired when project has saved successfully. */
  onSave: (project: IProject) => void;
}

const AddProjectDialog = (props: IProps) => {
  const auth = useAuth();
  // Project being worked on:
  const [project, setProject] = React.useState<IProject>({ uuid: null, name: null, users: []});
  // Is form valid?
  const [valid, setValid] = React.useState(false);
  // Currently saving? Will disable Save button.
  const [saving, setSaving] = React.useState(false);
  // Save error? Causes XHR dialog to appear.
  const [error, setError] = React.useState(null);

  // When dialog opens/closes, clear project.
  React.useEffect(() => {
    setProject({ uuid: null, name: null, users: []});
  }, [props.open]);

  const handleChange = (value: any) => {
    setProject(value);
  }

  const handleValidate = (valid: boolean) => {
    setValid(valid);
  }

  const handleSave = () => {
    setSaving(true);
    setError(null);
    ProjectApi.create(project, auth.access_token)
      .then(res => {
        ToastService.toast("Project created.");
        props.onSave(res);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setSaving(false));
  }

  const handleClose = () => {
    if(saving) return;
    setError(null);
    props.onClose();
  }

  return (
    <>
      <Dialog open={props.open && error === null} onClose={handleClose}>
        <Dialog.Header>
          Add project
        </Dialog.Header>
        <ProjectForm project={project} onChange={handleChange} onValidate={handleValidate}/>
        <Dialog.Footer align='space-between'>
          <Button secondary disabled={saving} onClick={props.onClose}>Cancel</Button>
          <Button primary disabled={!valid || saving} onClick={handleSave}>
            {saving  && <Icon loading name="circle-notch"/>}
            {!saving && "Save project"}
            {saving  && "Saving..."}                
          </Button>
        </Dialog.Footer>      
      </Dialog>
      <Dialog.Xhr open={error != null} error={error} onRetry={handleSave} onClose={() => setError(null)}/>
    </>
  )
}

export { AddProjectDialog }
