import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { MiniLoader } from '../MiniLoader/MiniLoader';

//
// Wraps the MiniLoader to appear in the center of an area that stretches
// to fill its container.
//

interface IProps {
  /** @ignore */
  className?: string;
  /** Color of dots. Defaults to `#333` */
  color?: string;  
}

const LoaderBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <MiniLoader size={60} color={props.color}/>
    </div>
  )
}

const Loader = styled(LoaderBase)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 999;
  background: rgba(0,0,0,0.5);
`

export { Loader }
