import * as React from 'react';
import styled from 'styled-components';

import { Checkbox, Flex, Form, Input, List } from '@independent-software/typeui/controls';
import { IProject } from '../../../api/project/IProject';
import { useFilter } from '../../../contexts/useFilter';
import { IUser } from '../../../api/user/IUser';
import { useAuth } from '../../../auth/useAuth';

interface IProps {
  project: IProject;
  onChange: (project: IProject) => void;
  onValidate: (valid: boolean) => void;
}

//
// Reusable project form for adding and editing projects
//

const ProjectForm = (props: IProps) => {
  const auth = useAuth();
  const filter = useFilter();

  const handleChange = (value: IProject) => {
    props.onChange({...value});
  }

  const handleValidate = (valid: boolean) => {
    props.onValidate(valid);
  }

  const handleToggleUser = (user: IUser) => {
    let users = props.project.users;
    // If user is present
    if(users.find(u => u.uuid === user.uuid)) {
      // ... then remove user
      users = users.filter(u => u.uuid !== user.uuid);
    } else {
      // ... else add user
      users.push(user);
    }
    // Update caller
    props.onChange({...props.project, users: users});
  }

  return (
    <DialogPadding>
    <Flex.Columns count={2}>
      <Form data={props.project} onChange={handleChange} onValidate={handleValidate}>
        <Form.Field
          name="name"
          value={props.project.name}
          label="Project name"
          hint="Please provide a short name for this project."
          required={{message: "Project name is required."}}
          minLength={{length: 3, message: "Project name must be at least 3 characters."}}
          maxLength={{length: 255, message: "Project name must not be longer than 255 characters."}}
          control={<Input fluid type="text" placeholder="Project name"/>}
        />
      </Form>
      <div>
        Users
        <Scroller>
          <List divided relaxed>
            {filter.users.map(user => <List.Item key={user.uuid}>
              <List.Content>
                <Checkbox 
                  checked={!!props.project.users.find(u => u.uuid == user.uuid) || user.uuid == auth.uuid} 
                  label={user.email} 
                  onChange={() => handleToggleUser(user)}/>
              </List.Content>
            </List.Item>)}
          </List>
        </Scroller>
      </div>
    </Flex.Columns>
  </DialogPadding>    
  )
}

const DialogPadding = styled.div`
  padding: 16px;
`

const Scroller = styled.div`
  height: 200px;
  overflow-y: scroll;
`

export { ProjectForm }
