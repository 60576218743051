import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Icon, Input, Label } from '@independent-software/typeui/controls';
import { Datum } from '@independent-software/typeui/formatters/Datum';

import { UI } from '../../controls/ui/UI';
import { Table } from '../../controls/Table';
import { LogApi } from '../../api/log/LogApi';
import { useAuth } from '../../auth/useAuth';
import { ILogitem } from '../../api/log/ILogitem';
import { Loader } from '../../controls';

const Log = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [logitems, setLogitems] = React.useState<ILogitem[]>([]);
  const [q, setQ] = React.useState<string>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const loadLogitems = () => {
    setLoading(true);
    setError(false);
    LogApi.list(auth.access_token)
    .then((res) => setLogitems(res))
    .catch((err) => setError(err))
    .finally(() => setLoading(false));
  }

  React.useEffect(() => loadLogitems(), []);

  const handleChangeQ = (value: string) => {
    setQ(value);
  }

  const handleGoHome = () => {
    navigate("/");
  }  

  const filteredLogitems = logitems.filter(p => q == null || (
    (p.name && p.name.toLowerCase().indexOf(q.toLowerCase()) != -1) ||
    (p.user && p.user.email.toLowerCase().indexOf(q.toLowerCase()) != -1)
  ));

  return (
    <>
      {loading && <Loader/>}
      <UI.Header>
        <Flex.Quick>
          <Icon name="chevron" rotated={180} bordered inverted onClick={handleGoHome} title="Go to projects"/>          
          <UI.Title>Log</UI.Title>
          <Label size="tiny">{logitems.length}</Label>
        </Flex.Quick>
        <Input clearable value={q} type="text" placeholder="Type to search log" onChange={handleChangeQ}/>
      </UI.Header>
      <UI.Body>
        <Table sort="email" data={filteredLogitems} loading={false} striped>
          <Table.Column weight={3} name="Name" sort="name" align="left">
            {(item: ILogitem) => item.name ?? '(name missing)'}
          </Table.Column>
          <Table.Column weight={1} name="Type" sort="type" align="left">
            {(item: ILogitem) => item.type}
          </Table.Column>          
          <Table.Column weight={1} name="Operation" sort="operation" align="left">
            {(item: ILogitem) => item.operation}
          </Table.Column>          
          <Table.Column weight={3} name="Who" sort="user.email" align="left">
            {(item: ILogitem) => item.user ? item.user.email : '-'}
          </Table.Column>                    
          <Table.Column weight={2} name="Date" sort="created_at" align="left">
            {(item: ILogitem) => <Datum.Distance value={item.created_at}/>}
          </Table.Column>
        </Table>
      </UI.Body>
      <UI.Footer>
      </UI.Footer>
    </>
  )
}

export { Log }
