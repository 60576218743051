import * as React from 'react';
import styled from 'styled-components';

import { Checkbox, Form, Input } from '@independent-software/typeui/controls';
import { Password } from '@independent-software/typeui/modules/Password';

import { IUser } from '../../../api/user/IUser';

interface IProps {
  user: IUser;
  onChange: (dataset: IUser) => void;
  onValidate: (valid: boolean) => void;  
}

const AddUserForm = (props: IProps) => {

  return (
    <DialogPadding>
      <Form data={props.user} onChange={props.onChange} onValidate={props.onValidate}>
        <Form.Field
          name="email"
          value={props.user.email}
          label="Email"
          hint="Please provide an email address for the user."
          required={{message: "Email is required."}}
          minLength={{length: 3, message: "Email must be at least 3 characters."}}
          maxLength={{length: 255, message: "Email name must not be longer than 255 characters."}}
          email={{message: "Email must be a valid email address."}}
          control={<Input autocomplete="off" fluid type="text" placeholder="Email"/>}
        />      

        <Form.Field
          required={{message: "Password is required."}}
          minLength={{length: 8, message: "Password must be at least 8 characters."}}
          maxLength={{length: 30, message:"Password may not be longer than 30 characters."}}
          noPattern={{pattern: '^(.{0,7}|.{31,}|[^0-9]*|[^A-Za-z]*|[a-zA-Z0-9]*)$', message: "Password must contain at least one digit and one special character."}}            
          width={1} label="Password"
          name="password" 
          value={props.user.password}
          control={<Password autocomplete="off" fluid placeholder="Password"/>}
          hint="Please fill out the user's password."
        />

        <Form.Field 
          name="manager"
          value={props.user.manager}
          control={<Checkbox label="User is manager"/>}
        />
      </Form>
    </DialogPadding>
  )
}

const DialogPadding = styled.div`
  padding: 16px;
`

export { AddUserForm }
