import * as React from 'react';
import styled from 'styled-components';
import { Dialog, Dropzone, Icon, ToastService } from '@independent-software/typeui/controls';

import { DatasetApi } from '../../../api/dataset/DatasetApi';
import { useAuth } from '../../../auth/useAuth';
import { IDataset } from '../../../api/dataset/IDataset';

interface IProps {
  /** Dataset to load data into. */
  dataset: IDataset;
  /** Is dialog currently open? */
  open: boolean;
  /** Fired when dialog closes. */
  onClose: () => void;
  /** Fired when data has been uploaded successfully. */
  onSave: (dataset: IDataset) => void;
}

const UploadDatasetDialog = (props: IProps) => {
  const auth = useAuth();
  const [error, setError] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);

  const upload = (file: File) => {
    setError(null);
    setUploading(true);
    DatasetApi.upload(props.dataset.uuid, file, auth.access_token)
    .then((res) => {
      ToastService.toast("Data uploaded.");
      props.onSave(res);
    })
    .catch((err) => {
      setError(err);
    })
    .finally(() => setUploading(false));
  }

  const handleAddFiles = (files: File[]) => {
    setFile(files[0]);
    upload(files[0]);
  }

  const handleClose = () => {
    if(uploading) return;
    setError(null);
    props.onClose();
  }

  return (
    <>
      <Dialog open={props.open && error === null} onClose={handleClose}>
        <Dialog.Header>
          Upload GeoJSON data
        </Dialog.Header>
        <Dialog.Content>
          <Wrapper>
            {uploading && <Overlay>
              <Icon name="circle-notch" loading/>&nbsp;Uploading...
            </Overlay>}
            <Dropzone 
              onAddFiles={handleAddFiles}
              message="Drop a .geojson file here to upload, or click to browse files."
            />
          </Wrapper>
        </Dialog.Content>
      </Dialog>  
      <Dialog.Xhr open={error != null} error={error} onRetry={() => upload(file)} onClose={() => setError(null)}/>
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  padding: 5px;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(255,255,255,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`

export { UploadDatasetDialog }
