import * as React from 'react';
import styled from 'styled-components';

import { Button, Icon, Panel } from '@independent-software/typeui/controls'

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/useAuth';
import { ManagerGravatar } from './ManagerGravatar';
import { AccountDialog } from '../../routes/home/dialogs/AccountDialog';

interface IProps {
  /** @ignore */
  className?: string;
}

const CurrentUserBase = (props: IProps) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [accountOpen, setAccountOpen] = React.useState(false);

  const handleUserClick = () => {
    setOpen(!open);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSignout = () => {
    setOpen(false);
    auth.signout();
  }    

  const handleAccount = () => {
    setOpen(false);
    setAccountOpen(true);
  }

  const handleGoToUsers = () => {
    navigate("/users");
  }

  const handleGoToLog = () => {
    navigate("/log");
  }

  return (
    <div className={props.className}>
      {auth != null && 
        <>
          <ManagerGravatar onClick={handleUserClick}/>
          <Panel open={open} onClose={handleClose} padded>
            <UserData>
              <ManagerGravatar size="tiny"/>
              <div>
                <Line><Icon name="envelope" color="#ccc"/> {auth.email}</Line>
              </div>
            </UserData>
            <Buttons>
              <Button negative onClick={handleSignout}>Sign out</Button>
              <Button onClick={handleAccount}>Account</Button>
              {auth.manager == true && <Button secondary onClick={handleGoToUsers}>Manage users</Button>}
              {auth.manager == true && <Button secondary onClick={handleGoToLog}>Log</Button>}
            </Buttons>
          </Panel>
          <AccountDialog open={accountOpen} onClose={() => setAccountOpen(false)}/>
        </>}
    </div>
  );
}

const UserData = styled.div`
  display: flex;
  align-items: flex-start;
  white-space: nowrap;
  margin-bottom: 10px;
  & > *:first-child {
    margin-right: 10px;
  }
`

const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 8px;
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;
`

const CurrentUser = styled(CurrentUserBase)`
  position: absolute;
  right: 26px;
  top: 26px;
`

export { CurrentUser };
