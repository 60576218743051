import { Api } from "../Api";
import { IDataset } from "./IDataset";

class DatasetApi {

  /* 
  REMOVED
  static list = async (project_uuid: string, access_token: string, signal?: AbortSignal): Promise<IDatasetProxy[]> => {
    return Api.Get<IDatasetProxy[]>(`datasets/${project_uuid}`, access_token, signal);
  }*/

  static upload = async(uuid: string, file: File, access_token: string, signal?: AbortSignal): Promise<IDataset> => {
    const formData = new FormData();
    formData.append('file', file);
    return Api.PostWithFormData<IDataset>(`dataset/upload/${uuid}`, formData, access_token, signal);
  }

  static create = async(projectUUID: string, dataset: IDataset, access_token: string, signal?: AbortSignal): Promise<IDataset> => {
    return Api.Post<IDataset>(`dataset/${projectUUID}`, dataset, access_token, signal);
  }

  static update = async(dataset: IDataset, access_token: string, signal?: AbortSignal): Promise<IDataset> => {
    return Api.Put<IDataset>(`dataset`, dataset, access_token, signal);
  }

  static delete = async(uuid: string, access_token: string, signal?: AbortSignal) => {
    return Api.Delete(`dataset/${uuid}`, access_token, signal);
  }

  static download = async(uuid: string, access_token: string, signal?: AbortSignal) => {
    return Api.Get(`dataset/geojson/${uuid}`, access_token, signal);
  }

}

export { DatasetApi }
