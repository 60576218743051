import * as React from 'react';

import { Button, Dialog, Icon, ToastService } from '@independent-software/typeui/controls';
import { IDataset } from '../../../api/dataset/IDataset';
import { DatasetApi } from '../../../api/dataset/DatasetApi';
import { useAuth } from '../../../auth/useAuth';
import { DatasetForm } from '../forms/DatasetForm';

interface IProps {
  /** Dataset being edited. */
  dataset: IDataset;
  /** Is the dialog currently open? */
  open: boolean;
  /** Fired when dialog is closed. */
  onClose: () => void;
  /** Fired when dataset has saved successfully. */
  onSave: (dataset: IDataset) => void;
}

const EditDatasetDialog = (props: IProps) => {
  const auth = useAuth();
  // Dataset being worked on:
  const [dataset, setDataset] = React.useState<IDataset>(null);
  // Is form valid?
  const [valid, setValid] = React.useState(false);
  // Currently saving? Will disable Save button.
  const [saving, setSaving] = React.useState(false);
  // Save error? Causes XHR dialog to appear.
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    setDataset(props.dataset);
  }, [props.dataset]);

  const handleChange = (value: any) => {
    setDataset(value);
  }

  const handleValidate = (valid: boolean) => {
    setValid(valid);
  }

  const handleSave = () => {
    setSaving(true);
    setError(null);
    DatasetApi.update(dataset, auth.access_token)
      .then(res => {
        ToastService.toast("Dataset updated.");
        props.onSave(res);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setSaving(false));
  }

  const handleClose = () => {
    if(saving) return;
    setError(null);
    props.onClose();
  }

  return (
    <>
      <Dialog open={props.open && error === null} onClose={handleClose}>
        <Dialog.Header>
          Edit dataset
        </Dialog.Header>
        <DatasetForm dataset={dataset} onChange={handleChange} onValidate={handleValidate}/>
        <Dialog.Footer align='space-between'>
          <Button secondary disabled={saving} onClick={handleClose}>Cancel</Button>
          <Button primary disabled={!valid || saving} onClick={handleSave}>
            {saving  && <Icon loading name="circle-notch"/>}
            {!saving && "Updated dataset"}
            {saving  && "Updating..."}
          </Button>
        </Dialog.Footer>      
      </Dialog>
      <Dialog.Xhr open={error != null} error={error} onRetry={handleSave} onClose={() => setError(null)}/>
    </>
  )
}

export { EditDatasetDialog }
