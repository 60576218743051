import * as React from 'react';
import styled from 'styled-components';
import { Button, Flex, Icon, Panel } from '@independent-software/typeui/controls';

import { useAuth } from '../../auth/useAuth';
import { IProject } from '../../api/project/IProject';
import { DatasetState } from '../../controls';
import { UI } from '../../controls/ui/UI';
import { IDataset } from '../../api/dataset/IDataset';
import { DeleteProjectDialog } from './dialogs/DeleteProjectDialog';
import { ProjectSelector } from './ProjectSelector';
import { useFilter } from '../../contexts/useFilter';
import { AddProjectDialog } from './dialogs/AddProjectDialog';
import { DatasetList } from './DatasetList';
import { Dataset } from './Dataset';
import { EditProjectDialog } from './dialogs/EditProjectDialog';

type TActivity = 'add_project' | 'add_dataset' | 'edit_project' | 'delete_project';

const Home = () => {
  const auth = useAuth();
  const filter = useFilter();
  
  const [activity, setActivity] = React.useState<TActivity>(null);
  const [projectPanelOpen, setProjectPanelOpen] = React.useState(false);

  const handleSelectDataset = (item: IDataset) => {
    filter.setDataset(item);
  }

  const handleAddProject = (project: IProject) => {
    filter.addProject(project);
    setActivity(null);
  }

  const handleEditProject = (project: IProject) => {
    filter.editProject(project);
    setActivity(null);
  }
  
  const handleDeleteProject = (project: IProject) => {
    filter.removeProject(project);
    setActivity(null);
  }

  return (
    <>
      <UI.Header>
        <Flex.Quick gap="16px">
          <UI.Title>Geostore{filter.project ? " | " + filter.project.name : ""}</UI.Title>
          {filter.project && <DatasetState state={filter.project.datasets.filter(d => d.state !== 'ok').length > 0 ? 'error' : 'ok'}/>}
        </Flex.Quick>
        <Flex.Quick gap="16px">
          <Flex.Quick>
            <ProjectSelector
              projectUUID={filter.project ? filter.project.uuid : null}
              onSelect={(uuid:string) => filter.loadProject(uuid)}
            />
            {auth.manager == true && <>
              <Button primary icon onClick={() => setActivity('add_project')}><Icon title="Add project" name="plus"/></Button>
              {filter.project != null && <>
                <div style={{position: 'relative'}}>
                <Button icon onClick={() => setProjectPanelOpen(!projectPanelOpen)}><Icon name="edit"/></Button>
                <Panel open={projectPanelOpen} onClose={() => setProjectPanelOpen(false)} padded>
                  <div style={{display: 'flex', gap: '8px'}}>
                    <Button onClick={() => setActivity('edit_project')}><Icon name="edit"/> Edit project</Button>
                    <Button negative onClick={() => setActivity('delete_project')}><Icon name="trash"/> Delete project</Button>              
                  </div>
                </Panel>                
                </div>
              </>}
            </>}
          </Flex.Quick>
        </Flex.Quick>
      </UI.Header>
      <UI.Body>
        {filter.project === null && <Full>
          Please select (or create) a project.
        </Full>}
        {filter.project !== null && <>
          <SplitLeft>
            <DatasetList onSelect={handleSelectDataset}/>
          </SplitLeft>
          <SplitRight>
            <Dataset/>
          </SplitRight>
        </>}
      </UI.Body>

      {/* Dialogs */}
      <AddProjectDialog open={activity === 'add_project'} onClose={() => setActivity(null)} onSave={handleAddProject}/>
      <EditProjectDialog project={filter.project} open={activity == 'edit_project'} onClose={() => setActivity(null)} onSave={handleEditProject}/>
      <DeleteProjectDialog project={filter.project} open={activity === 'delete_project'} onClose={() => setActivity(null)} onDelete={handleDeleteProject}/>
    </>
  )
}

const SplitLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 50%;
  bottom: 0;
`

const SplitRight = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  left: 50%;
  bottom: 0;
  border-left: solid 1px #ddd;
`

const Full = styled.div`
  padding: 32px;
`

export { Home }
