import * as React from 'react';
import styled, { css, keyframes } from '@independent-software/typeui/styles/Theme';

import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { ITableColumnProps, TableColumn } from './TableColumn';
import { lighten } from '@independent-software/typeui/helper/lighten';

interface IProps {
  /** @ignore */
  className?: string;
  /** 
   * TableColumn specifications. These are a set of `TableColumn` children,
   * each of which set a column name, weight, sort key and sort direction, and
   * cell content alignment. The content of a TableColumn is a function which
   * is given a data row and returns formatted cell content.
   */
  children?: React.ReactElement<ITableColumnProps> | React.ReactElement<ITableColumnProps>[];
  /** Array of data to show in the table */
  data: any[];
  /** Current sort column, if any. This column will be highlighted. */
  sort?: string;
  /** Optional custom sort value retrieval. */
  onSortValue?: (item: any, key: string) => any;
  /** If set, horizontal and vertical grid lines are drawn between cells. */
  grid?: boolean;
  /** If set, rows will be striped. */
  striped?: boolean;
  /** If set, rows will have a hover effect. */
  hover?: boolean;
  /** If set, show a loading animation. */
  loading?: boolean;
  /**
   * Fired when a row is clicked.
   */
  onClick?: (item: any) => void;
  /** 
   * Fired when a row is hovered. 
   */
  onHover?: (item: any) => void;
  /** 
   * If present, a checks column is added. This callback is fired when a 
   * cell checked state is toggled. The table's dataset is returned, with
   * a `checked` key on each element. 
   */
  onCheck?: (data: any) => void;
  /** 
   * If present, a column deletion option is added. 
   * This callback is fired when the delete option is called, and `index`
   * is the 0-based column index.  
   */
  onDeleteColumn?: (index: number) => void;
  /** 
   * If present, an add column option is added. 
   * This callback is fired when the add column button is clicked.
   */
  onAddColumn?: () => void;
  /**
   * If present, a filter column is added.
   * The filter contents are shown in a panel.
   */
  filter?: React.ReactNode;
}

const TableBase = (props: IProps) => {
  // Current sort key.
  const [sort, setSort] = React.useState(props.sort);
  // Is sort direction reversed?
  const [reverse, setReverse] = React.useState(false);

  // Sort data:
  const handleSort = (newSort: string, defaultReverse: boolean) => {
    if(sort === newSort) {
      setReverse(!reverse);
    } else {
      setReverse(defaultReverse)
    }
    setSort(newSort);
  }

  // Get data, applying the current sort key and direction.
  const getData = () => {
    // Build new data array, order by new key.
    const newData = 
      [...props.data]
        .sort((a,b) => {
          const vA:any = (props.onSortValue ? props.onSortValue(a, sort) : (a as any)[sort]) ?? "";
          const vB:any = (props.onSortValue ? props.onSortValue(b, sort) : (b as any)[sort]) ?? "";
          if(vA.localeCompare) return vA.localeCompare(vB);
          return vA - vB;
        })
        
    // Reverse depending on sort direction.
    if(reverse === true) newData.reverse();
    return newData;
  }
  
  // If a row check is clicked:
  // * An `index` of null means all checks must be inverted
  // * A number index is the 0-based row index of the row that must be inverted.
  const handleCheck = (index: number) => {
    if(index === null) {
      props.onCheck(props.data.map(x => { return { ...x, checked: !x.checked } }));
    } else {
      (props.data[index] as any).checked = !(props.data[index] as any).checked;
      props.onCheck(props.data);
    }
  }

  const data = getData();

  return (
    <div className={props.className}>
      <LoadingBar/>
      <TableInner>
      
        <table>

          <TableHeader 
            sort={sort} 
            reverse={reverse} 
            onClick={handleSort} 
            onCheck={props.onCheck ? handleCheck : null} 
            onDeleteColumn={props.onDeleteColumn} 
            onAddColumn={props.onAddColumn}
            filter={props.filter}
          >
            {props.children}
          </TableHeader>

          <TableBody 
            data={data} 
            onClick={props.onClick}
            onHover={props.onHover}
            onCheck={props.onCheck ? handleCheck : null} 
            hasAddColumn={!!props.onAddColumn}
            hasFilter={!!props.filter}
          >
            {props.children}
          </TableBody>

        </table>
      </TableInner>
    </div>
  );
}

const lineAnimation = keyframes`
  0%   { width: 0%;   }
  50%  { width: 100%; }
  100% { width: 0%;   }
`;

const TableInner = styled.div``;
const LoadingBar = styled.div``;

const TableStyled = styled(TableBase)`
  position:    relative;
  height:      100%;
  font-size:   14px;
  flex:        1;

  ${LoadingBar} {
    position: absolute;
    z-index: 2;
    top: 47px;
    left: 0;
    width: 0;
    height: 2px;
    background: ${p => lighten(0.2, p.theme.primaryColor)};
    ${p => p.loading && css`animation: ${lineAnimation} 20s linear infinite;`}
  }
  
  ${TableInner} {
    position:  absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;

    /* Table is full-width: */
    table {
      width:     100%;
      position:  relative;
      border-collapse: collapse; 
      table-layout: fixed;
    }

    /* Row striping and hovering: */
    tr {
      background-color: #fff;
      ${p => p.striped && css`
        &:nth-child(2n+2) {
          background-color: #f8f8f8;
        }
      `}
      ${p => p.hover && css`
        &:hover {
          background-color: #DFE9F2;
        }
      `}
    }
    tr.selected {
      background-color: #B8D0E6;
    }

    th, td {
      height:         48px;
      vertical-align: middle;
      box-sizing:     border-box;
      white-space:    nowrap;
      text-align:     left;
      user-select:    none;
    }

    th {
      /* Position */
      position:  sticky;
      top:       0;
      z-index:   1;

      /* Dimensions */
      padding:     0 16px 0 16px;

      /* Appearance */
      background:  #333;
      color:       #fff;
    }

    td {
      text-overflow:  ellipsis;
      overflow:       hidden;
      padding:        0 16px 0 16px;
      color:        #333;
    }

    /// Only 2nd row and onwards has a top border.
    ${p => p.grid && css`
      tr:not(:first-child) td {
        border-top: solid 1px #eee;
      }
    `}

    // Every cell has a left border, except
    // - first cell
    // - 2nd cell, if checkboxes are present
    // - last n cells, if filter and/or addcolumn are present.
    ${p => p.grid && css` 
      td:not(:first-child) {
        border-left: solid 1px #eee;
      }
      ${!!p.onCheck && css`
        td:nth-child(2) { border-left: none; }
      `}
      ${(!!p.onAddColumn || !!p.filter) && css`
        td:nth-last-child(-n+1) { border-left: none; }
      `}
      ${!!p.onAddColumn && !!p.filter && css`
        td:nth-last-child(-n+2) { border-left: none; }
      `}
    `}
  }
`



const Table = (props: IProps) => <TableStyled {...props}/>

Table.Column = TableColumn;

export { Table }
