import { Body } from "./Body";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Title } from "./Title";
import { Subtitle } from "./Subtitle";

class UI {
  static Header = Header;
  static Body = Body;
  static Footer = Footer;
  static Title = Title;
  static Subtitle = Subtitle;
}

export { UI }
