import { Api } from "../Api";
import { ILogitem } from "./ILogitem";

class LogApi {

  static list = async (access_token: string, signal?: AbortSignal): Promise<ILogitem[]> => {
    return Api.Get<ILogitem[]>(`log`, access_token, signal);
  }

}

export { LogApi }