import * as React from 'react'
import styled from "styled-components"
import { CurrentUser } from './CurrentUser';

interface IProps {
  /** @ignore */
  className?: string;  
  /** @ignore */
  children?: React.ReactNode;
}

const HeaderBase = (props: IProps) => 
  <div className={props.className}>
    <Logo>
      <use xlinkHref={"sprites.svg#logo-notext"}></use>
    </Logo>
    {props.children}
    <CurrentUser/>
  </div>

const Logo = styled.svg`
  position: absolute;
  left: 16px;
  top: 16px;
  width: 48px;
  height: 48px;
`

const Header = styled(HeaderBase)`
  position: relative;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 72px;
  padding-right: 68px;  
  border-bottom: solid 1px #ddd;
`

export { Header }
