import * as React from 'react';

import { Dialog } from '@independent-software/typeui/controls';

import { useAuth } from '../auth/useAuth';
import { FilterContext, IFilter } from './FilterContext';
import { ProjectApi } from '../api/project/ProjectApi';
import { IProjectProxy } from '../api/project/IProjectProxy';
import { IProject } from '../api/project/IProject';
import { Loader } from '../controls';
import { IDataset } from '../api/dataset/IDataset';
import { IUser } from '../api/user/IUser';
import { UserApi } from '../api/user/UserApi';
import { DatasetApi } from '../api/dataset/DatasetApi';

interface IProps {
  children?: React.ReactNode;
}

const Filter = (props: IProps) => {
  const auth = useAuth();
  const retry = React.useRef(null);
  const giveup = React.useRef(null);
  const mapLoadController = React.useRef<AbortController>(null);
  
  const [projects, setProjects] = React.useState<IProjectProxy[]>([]);
  const [project, setProject] = React.useState<IProject>(null);
  const [dataset, setDataset] = React.useState<IDataset>(null);
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [mapdata, setMapdata] = React.useState(null);
  const [mapLoading, setMapLoading] = React.useState(false);

  React.useEffect(() => loadProjects(), []);
  React.useEffect(() => loadUsers(), []);
  React.useEffect(() => selectFirstDataset(), [project && project.uuid]);
  React.useEffect(() => loadMapdata(), [dataset]);

  const loadMapdata = () => {
    if(dataset == null) return;
    setMapLoading(true);
    if(mapLoadController.current) mapLoadController.current.abort();
    mapLoadController.current = new AbortController();
    DatasetApi.download(dataset.uuid, auth.access_token, mapLoadController.current.signal)
    .then((res) => setMapdata(res))
    .catch(() => setMapLoading(false))
    .finally(() => setMapLoading(false));
  }

  const selectFirstDataset = () => {
    if(project === null || project.datasets.length == 0) {
      setDataset(null);
    } else {
      setDataset(project.datasets[0]);
    }
  }

  // Load projects. Executed as soon as page loads.
  // If there are any projects that the current user can access, then this
  // will also load the first project.
  const loadProjects = () => {
    setError(null);
    setLoading(true);
    retry.current = () => loadProjects();
    giveup.current = () => {};
    ProjectApi.list(auth.access_token)
    .then((res) => {
      setProjects(res);
      if(res.length > 0) {
        loadProject(res[0].uuid);
      }
    })
    .catch((err) => setError(err))
    .finally(() => setLoading(false));
  }

  // Load new current project by UUID.
  const loadProject = (uuid: string) => {
    setError(null);
    setLoading(true);
    retry.current = () => loadProject(uuid);
    giveup.current = () => { setError(null); setProject(null) };
    ProjectApi.get(uuid, auth.access_token)
    .then(res => {
      setProject(res);
    })
    .catch((err) => {
      setError(err);
    })
    .finally(() => setLoading(false));
  }

  const loadUsers = () => {
    // Do not load users if current user isn't manager.
    if(!auth.manager) return;

    setError(null);
    retry.current = () => loadUsers();
    giveup.current = () => {};
    UserApi.list(auth.access_token)
    .then((res) => {
      setUsers(res);
    })
    .catch((err) => setError(err));
  }  

  const handleAddProject = (project: IProject) => {
    // Add new project to list of projects; also sort list by name.
    setProjects([...projects, { name: project.name, uuid: project.uuid, datasets: 0, state: 'ok' }].sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
    setProject(project);
  }

  const handleEditProject = (project: IProject) => {
    // Replace project in list of projects; also sort list by name.
    setProjects([...projects.filter(p => p.uuid !== project.uuid), { name: project.name, uuid: project.uuid, datasets: 0, state: 'ok' }].sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
  }

  const handleRemoveProject = (project: IProject) => {
    setProjects([...projects.filter(p => p.uuid != project.uuid)]);
    setProject(null);
  }

  const handleAddUser = (user: IUser) => {
    // Add new user to list of users; also sort list by email.
    setUsers([...users, user].sort((a,b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase())));    
  }

  const handleEditUser = (user: IUser) => {
    // Replace user in list of users; also sort list by email.
    setUsers([...users.filter(p => p.uuid !== user.uuid), user].sort((a,b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase())));
  }

  const handleRemoveUser = (user: IUser) => {
    // Remove user from list of users.
    setUsers([...users.filter(p => p.uuid !== user.uuid)]);
  }

  const value: IFilter = {
    projects: projects,
    project: project,
    dataset: dataset,
    users: users,
    mapdata: mapdata,
    mapLoading: mapLoading,
    setProject: setProject,
    loadProject: loadProject,
    addProject: handleAddProject,
    editProject: handleEditProject,
    removeProject: handleRemoveProject,
    setDataset: setDataset,
    addUser: handleAddUser,
    editUser: handleEditUser,
    removeUser: handleRemoveUser
  }

  return (
    <FilterContext.Provider value={value}>
      {loading && <Loader/>}
      {props.children}
      <Dialog.Xhr open={error != null} error={error} onRetry={retry.current} onClose={giveup.current}/>
    </FilterContext.Provider>
  );
}

export { Filter }
