import * as React from 'react';

import { Dropdown } from '@independent-software/typeui/controls';

import { IProjectProxy } from '../../api/project/IProjectProxy';
import { useFilter } from '../../contexts/useFilter';
import { DatasetState } from '../../controls';

interface IProps {
  projectUUID: string;
  onSelect: (uuid: string) => void;
}

const ProjectSelector = (props: IProps) => {
  const filter = useFilter();

  const handleSelectProject = (projectProxy: IProjectProxy) => {
    props.onSelect(projectProxy.uuid);
  }

  return (
    <div style={{width: '300px'}}>
      <Dropdown fluid data={filter.projects} value={filter.projects.find(p => p.uuid == props.projectUUID) ?? null} label={(p:IProjectProxy) => p.name} onChange={handleSelectProject} placeholder="Select project">
        <Dropdown.Column weight={3}>{(p: IProjectProxy) => p.name}</Dropdown.Column>
        <Dropdown.Column weight={1} align='right'>{(p: IProjectProxy) => <DatasetState state={p.state}/>}</Dropdown.Column>
      </Dropdown>
    </div>
  )
}

export { ProjectSelector }
